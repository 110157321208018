<template>
  <div
    class="container-fluid"
    style="background-color: #fff; overflow: hidden; paddong-bottom: -10px"
  >
    <div class="block">
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>標題(中文)</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="請輸入標題(中文)"
                        maxlength="50"
                        v-model="SurveyChinese"
                    />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>標題(英文)</label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="請輸入標題(英文)"
                        maxlength="100"
                        v-model="SurveyEnglish"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="page">
      <div class="left-box">
        <draggable v-model="itemList" class="list-group" handle=".handle">
          <li
            class="list-group-item"
            v-for="(element, index) in itemList"
            :key="element.id"
          >
            <div class="handle">
              <aside class="seq">
                  <div>
                      <eva-icon fill="#fff" name="radio-button-on" width="18" height="18" class="icon" v-if="element.FieldType === 'Radio'" />
                      <eva-icon fill="#fff" name="checkmark-square-2-outline" width="18" height="18" class="icon" v-if="element.FieldType === 'Checkbox'" />
                      <eva-icon fill="#fff" name="calendar-outline" width="18" height="18" class="icon" v-if="element.FieldType === 'DateTime'" />
                  </div>
                  <div :class="{ mark: element.Required }" >{{ ++index }}</div>
              </aside>
              <main class="item">
                <Input
                  v-if="element.FieldType === 'Input'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
                <Textarea
                  v-if="element.FieldType === 'TextArea'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
                <Radio
                  v-if="element.FieldType === 'Radio'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
                <Checkbox
                  v-if="element.FieldType === 'Checkbox'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
                <Date
                  v-if="element.FieldType === 'DateTime'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
                <Remark
                  v-if="element.FieldType === 'Remark'"
                  :data="{...element, status}"
                  @addItem="addItem"
                  @delItem="delItem"
                />
              </main>
            </div>
          </li>
        </draggable>
      </div>
      <div class="right-box">
        <div class="type-button-group">
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '單行文字' })"
          >
            單行文字
          </button>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '多行文字' })"
          >
            多行文字
          </button>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '單選題' })"
          >
            單選題
          </button>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '多選題' })"
          >
            多選題
          </button>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '日期' })"
          >
            日期
          </button>
          <button
            type="button"
            class="btn btn-outline-info btn-sm"
            @click="add({ type: '備註' })"
          >
            備註
          </button>
          <button
            type="button"
            class="btn btn-sm"
            style="background: #3DBA90; color: #fff"
            @click.prevent="save"
          >
            儲存
          </button>
          <button
            type="button"
            class="btn btn-sm"
            style="background: gray; color: #fff"
            @click.prevent="goBack"
          >
            返回
          </button>
          <!-- <button
            type="button"
            class="btn btn-sm"
            style="background: #3DBA90; color: #fff"
            data-toggle="modal"
            data-target="#exampleModal"
          >
            預覽
          </button> -->
        </div>
      </div>
    </div>

    <div id="exampleModal" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <perfect-scrollbar class="modal-body">
            <h4 class="mb-4" style="margin-left: 25px; color: #3DBA90">
              <strong>問卷</strong>
            </h4>
            <div
              class="preview-content"
              v-for="(item, index) in itemList"
              :key="item.id"
            >
              <div class="preview-item">
                <strong>{{ ++index }}.</strong>
              </div>
              <div class="preview-item flex-2">
                <div class="item-title">
                  <h5 style="margin-top: 5px">
                    <strong>{{ item.title }}</strong
                    ><span v-if="item.require" class="mark">*</span>
                  </h5>
                  <p style="color: #BDBDBD; font-size: 14px;">
                    {{ item.description }}
                  </p>
                </div>
                <div>
                  <input
                    v-if="item.type === 'input'"
                    class="preview-input"
                    type="text"
                  />
                  <textarea
                    v-if="item.type === 'textarea'"
                    rows="5"
                    class="preview-textarea"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-4 mb-4">
              <button
                type="button"
                class="btn btn-success"
                style="padding: 5px 40px"
              >
                送出
              </button>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import draggable from "vuedraggable";
import Input from "@/components/Forms/Input";
import Textarea from "@/components/Forms/Textarea";
import Radio from "@/components/Forms/Radio";
import Checkbox from "@/components/Forms/Checkbox";
import Date from "@/components/Forms/Date";
import Remark from "@/components/Forms/Remark";

import { apiAddSurveyItem, apiEditSurveyItem } from '@/apis/externalQnrManage'

let id = 0;

export default {
  name: "New",

  components: {
    draggable,
    Input,
    Textarea,
    Radio,
    Checkbox,
    Date,
    Remark,
  },
  data() {
    return {
      Guid: null,
      SurveyChinese: null,
      SurveyEnglish: null,
      itemList: [],
      status: 'new'
    };
  },
  methods: {
    ...mapActions(['updateLoading']),
    add({ type }) {
        switch (type) {
            case "單行文字":
                this.itemList.push({
                    FieldType: "Input",
                    name: id + " 單行文字",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
            case "多行文字":
                this.itemList.push({
                    FieldType: "TextArea",
                    name: id + " 多行文字",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
            case "單選題":
                this.itemList.push({
                    FieldType: "Radio",
                    name: id + " 單選題",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
            case "多選題":
                this.itemList.push({
                    FieldType: "Checkbox",
                    name: id + " 多選題",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
            case "日期":
                this.itemList.push({
                    FieldType: "DateTime",
                    name: id + " 日期",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
            case "備註":
                this.itemList.push({
                    FieldType: "Remark",
                    name: id + " 備註",
                    id,
                    title: null,
                    Guid: "00000000-0000-0000-0000-000000000000"
                });
                break;
        }
      id++;
      window.isDirty = true
    },
    addItem(e) {
      const dataIndex = this.itemList.findIndex((item) => item.id === e.id);
      this.itemList.splice(dataIndex, 1, { ...e });
    },
    delItem(e) {
      if(e.id) {
        const dataIndex = this.itemList.findIndex((item) => item.id === e.id);
        this.itemList.splice(dataIndex, 1);
      } else {
        const dataIndex = this.itemList.findIndex((item) => item.Guid === e.Guid);
        this.itemList.splice(dataIndex, 1);
      }
      // const dataIndex = this.itemList.findIndex((item) => item.id === e.id);
      // this.itemList.splice(dataIndex, 1);
    },
    addSurveyItem() {
      
      this.updateLoading(true)
      apiAddSurveyItem({
        SurveyChinese: this.SurveyChinese,
        SurveyEnglish: this.SurveyEnglish,
        FieldContent: this.itemList,
        CreateUser: this.$cookies.get("userId")
      }).then((response) => {

          if(response.status === 200) {
            this.itemList = [];

            this.$router.push({ name: "問卷管理,外部疫調管理" })

            this.$notify({
                title: "成功",
                message: "成功新增調查項目",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
    },
    editSurveyItem() {
      this.updateLoading(true)
      apiEditSurveyItem({
        Guid: this.Guid,
        SurveyChinese: this.SurveyChinese,
        SurveyEnglish: this.SurveyEnglish,
        FieldContent: this.itemList,
        UpdateUser: this.$cookies.get("userId")
      }).then((response) => {

          if(response.status === 200) {
            this.itemList = [];

            this.$router.push({ name: "問卷管理,外部疫調管理" })

            this.$notify({
                title: "成功",
                message: "成功編輯調查項目",
                type: "success",
                duration: 3500,
            });
          }

          this.updateLoading(false)
      })
    },
    getSurveyItem(guid) {
      const vm = this;
      const api = `${window.BaseUrl.api}/ExternalQnr/GetSurveyItem/${guid}`;

      this.updateLoading(true)
      
      vm.$http.get(api).then((response) => {

            if(response.status === 200) {
              this.Guid = response.data.Guid
              this.SurveyChinese = response.data.SurveyChinese
              this.SurveyEnglish = response.data.SurveyEnglish
              this.itemList = response.data.FieldContent.map(item => {
                return {
                  ...item,
                  id: item.Guid,
                  Finished: true
                }
              })
            }

            this.updateLoading(false)
      })
      .catch(() => {
        this.updateLoading(false)
      })
    },
    save() {
      let verify = true //  預設驗證成功
      let hint = '您有題目尚未完成'

      this.itemList.forEach(item => {
        if(!item?.Finished) {
          verify = false
        }
      })

      if(this.SurveyChinese === null || this.SurveyEnglish === null) {
        verify = false
        hint = '您有標題尚未輸入'
      }
      
      if(verify) {
        if(this.$route.query.Guid === null) {
          this.addSurveyItem()
        } else {
          this.editSurveyItem()
        }
      } else {
        alert(`${hint}`)
      }

      
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  created() {
    if(this.$route.query.Guid !== null) {
        this.getSurveyItem(this.$route.query.Guid)
        this.status = 'edit'
      }
  }
};

window.addEventListener("beforeunload", function (e) {
    if (window.isDirty) {
        (e || window.event).returnValue = ""; // Gecko + IE
        return ""; // Gecko + Webkit, Safari, Chrome etc.
    }

    return undefined;
});

</script>

<style lang="scss" scoped>
.page {
  display: flex;
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  height: 0;
  .left-box {
    display: block;
    width: 80%;
    height: 79vh;
    overflow-y: auto;
    .seq {
      cursor: default;
      display: flex;
      float: 1 1 12%;
      margin-left: 6px;

      div:nth-child(1) {
        // display: inline-block;
        width: 32px;
        height: 32px;
        // text-align: center;
        border-radius: 3px;
        cursor: pointer;
        // vertical-align: top;
        margin-right: 2px;
        background-color: #bdbdbd;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      div:nth-child(2) {
        display: inline-block;
        width: 32px;
        height: 32px;
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        vertical-align: top;
        position: relative;
        padding-top: 6px;
        font-size: 14px;
        color: #fff;
        background-color: #5fcba7;
      }

      
    }
    .item {
      display: flex;
      flex-direction: column;
      float: 1 1 100%;
      margin: 0 15px;
    }
  }
  .right-box {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 0 10px;
    .type-button-group {
      display: flex;
      flex-direction: column;
      height: 100%;
      background: #f6f6f6;
    }
    button {
      border-style: dashed;
      margin: 0 40px 10px 40px;
    }
  }
}

.modal-body {
  padding: 30px;
}

.preview-content {
  display: flex;
  height: auto;
  padding: 15px 30px;
  .preview-item {
    flex: 0 0 5%;
    display: flex;
  }
  .flex-2 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 85%;
    flex-direction: column;
  }
  .item-title {
    margin-bottom: 10px;
  }
  .preview-input {
    width: 100%;
    line-height: 1.6;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #bdbdbd;
    border-left: none;
    outline: none;
    box-shadow: none;
  }
  .preview-input:focus {
    border-bottom: 1px solid #000;
  }
  .preview-textarea {
    width: 100%;
    line-height: 1.6;
    border: 1px solid #bdbdbd;
    padding: 5px;
    outline: none;
    box-shadow: none;
    border-radius: 3px;
  }
  .preview-textarea:focus {
    border: 1px solid #000;
  }
}

.handle {
    display: flex;
    align-items: flex-start;

    .item {
        flex: 1;
    }
}


.mark::before {
  content: '*';
  position: absolute;
  top: 0;
  left: 6px;
}
</style>
